/* eslint-disable sonarjs/cognitive-complexity */
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import {
  BigIntOr0,
  service,
  useFlowPower,
  useNetwork,
  useSession,
  useTokenItem,
  useVaultActions,
} from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import { type FC } from 'react';
import { FaGasPump } from 'react-icons/fa6';
import { RiInformationFill } from 'react-icons/ri';

import {
  flowPowerId,
  useFlowPowerModalActions,
} from '~/lib/hooks/useModals/useComplexModals';

// import { mixpanelAnalytics } from 'src/kirobo/utils/mixpanelAnalytics';

interface IFlowPowerPreviewProps {
  label: string;
  isDisabled?: boolean;
  narrowMode: boolean;
}

export const FlowPowerPreview: FC<IFlowPowerPreviewProps> = ({
  label,
  isDisabled,
  narrowMode,
}) => {
  const { open: openFlowPower } = useFlowPowerModalActions(flowPowerId);
  // const { open: openRemoveFlowPower } = useRemoveFlowPowerModalActions(removeFlowPowerId);
  const {
    flow: { addPower, removePower },
  } = useVaultActions();
  const {
    raw: {
      balance: { native: powerBalanceWei },
      missing: { native: powerMissingWei },
    },
    // fmt: {
    // balance: { native: powerBalanceEtherFmt },
    // missing: { native: powerMissingEtherFmt },
    // },
  } = useFlowPower();
  const {
    account: {
      gasCredit: { native: nativeGasCredit },
    },
    connectionInfo: { isBlocked },
  } = useSession();
  const toast = useToast();

  const { item: walletNative } = useTokenItem({ account: 'wallet' }); // TODO: Tal - get it from useNetwork
  const {
    data: {
      raw: { ethPriceInUsd: nativeInUSD },
    },
  } = useNetwork(); // TODO: Tal - rename to nativePriceInUsd
  // const nativeInUSD = walletNative?.raw.price.usd;

  const powerBalancePlusCreditWei =
    BigIntOr0(powerBalanceWei) + BigIntOr0(nativeGasCredit);
  const powerBalancePlusCreditWeiFmt = service.formatting.prebuild.formatValue({
    service: 'tokens',
    name: 'total',
    value: powerBalancePlusCreditWei.toString(),
    decimals: 18,
    digits: 6,
  });

  const symbol = walletNative?.raw.symbol || 'ETH';

  const { isRunning: isRunningAddPower } = addPower.state;
  const { isRunning: isRunningRemovePower } = removePower.state;

  const balanceInUSD =
    (Number(powerBalancePlusCreditWei) / 1e18) * Number(nativeInUSD);
  const balanceInUSDFormatted = service.formatting.prebuild.formatValue({
    service: 'power',
    name: 'balance',
    value: balanceInUSD,
    decimals: 0,
    digits: 2,
    format: '0,0.00',
  });

  const isRunning = isRunningAddPower || isRunningRemovePower;

  const showNeededTokenAlert = BigIntOr0(powerMissingWei) > BigIntOr0(0);

  const t = useTranslations();

  const handleOpenFlowPower = () => {
    if (isBlocked) {
      toast({
        title: t('action-not-available-in-your-region'),
        description: t(
          'this-operation-cannot-be-executed-from-your-location-due-to-regulatory-restrictions'
        ),
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    openFlowPower({});
  };

  return (
    <Stack alignItems="flex-start" gap="20px" spacing="0">
      <Stack spacing="0" gap="12px" alignItems="flex-start" p="0">
        <Tooltip
          label={
            showNeededTokenAlert
              ? t('insufficient-gas-for-active-intents')
              : t('the-gas-station-description')
          }
        >
          {!narrowMode ? (
            <Button
              alignSelf="center"
              onClick={handleOpenFlowPower}
              isDisabled={isDisabled || isRunning}
              rightIcon={<Icon as={RiInformationFill} boxSize="16px" />}
              style={{ textDecoration: 'none' }}
              color={showNeededTokenAlert ? 'red.400' : 'blue.400'}
              fontSize="16px"
              fontWeight="600"
              colorScheme="blue"
              variant="link"
              opacity={isDisabled ? '0.2' : 'none'}
              ml="4px"
            >
              {t('my-gas-station')}
            </Button>
          ) : (
            <IconButton
              alignSelf="center"
              onClick={handleOpenFlowPower}
              isDisabled={isDisabled || isRunning}
              icon={<Icon as={RiInformationFill} boxSize="16px" />}
              style={{ textDecoration: 'none' }}
              color={showNeededTokenAlert ? 'red.400' : 'blue.400'}
              fontSize="16px"
              fontWeight="400"
              colorScheme="blue"
              variant="link"
              opacity={isDisabled ? '0.2' : 'none'}
              ml="4px"
              aria-label="My Gas Station"
            />
          )}
        </Tooltip>
        <HStack
          bg={narrowMode ? 'transparent' : 'gray.50'}
          _dark={{ bg: narrowMode ? 'transparent' : 'gray.900' }}
          py="8px"
          px={narrowMode ? '0' : '8px'}
          width={narrowMode ? '48px' : '232px'}
          transition="all 0.4s ease-out"
          gap="16px"
          rounded="8px"
          justifyContent="space-between"
        >
          <Stack
            spacing="0"
            opacity={isDisabled ? '0.2' : 'none'}
            display={narrowMode ? 'none' : 'block'}
            transition="all 0.4s ease-out"
          >
            <Text whiteSpace="nowrap" fontSize="18px" fontWeight="600">
              {balanceInUSDFormatted} USD
            </Text>
            <HStack
              data-cy="fct-power-balance"
              spacing="3px"
              fontSize="14px"
              fontWeight="400"
            >
              <Text
                whiteSpace="nowrap"
                fontSize="14px"
                fontWeight="600"
                color="gray.400"
                data-cy="power-amount-available"
              >
                {powerBalancePlusCreditWeiFmt} {symbol}
              </Text>
            </HStack>
          </Stack>

          <Tooltip label={label}>
            <IconButton
              bg={!narrowMode ? undefined : 'gray.900'}
              data-cy="flow-power-icon"
              boxSize="48px"
              onClick={handleOpenFlowPower}
              isDisabled={isDisabled}
              isLoading={isRunning}
              aria-label="My Gas Station"
              icon={<Icon as={FaGasPump} boxSize="24px" />}
            />
          </Tooltip>
        </HStack>
      </Stack>
    </Stack>
  );
};
