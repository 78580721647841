'use client';

import type { StackProps } from '@chakra-ui/react';
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Heading,
  Icon,
  IconButton,
  Spacer,
} from '@chakra-ui/react';
import { HambergerMenu, UserOctagon, Wallet } from 'iconsax-react';
import { usePathname } from 'next/navigation';
import { useCallback, type FC } from 'react';
import { useDeviceSelectors } from 'react-device-detect';
import { useAccount, useNetwork } from 'wagmi';

import { KiroboLogo } from '~/app/components/Sidebar/KiroboLogo';
import { Socials } from '~/lib/components/Socials';
import { isColorModeOff } from '~/lib/helpers/flags';
import { useUser } from '~/lib/hooks/useUser/useUser';
import ThemeToggle from '~/lib/layout/ThemeToggle';
import { shortenAddress } from '~/lib/utils/address';

import { useSession } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import { useConnectHandler } from '~/lib/hooks/general/useConnectHandler';
import {
  drawerMenuId,
  useDrawerMenuActions,
} from '~/lib/hooks/useModals/useComplexModals';
import { NetworkSwitcher } from './NetworkSwitcher';

const replaceToIntents = (text: string) => {
  return text
    .replace('flows', 'intents')
    .replace('apps', 'intents')
    .replace('app', 'intent');
};

// eslint-disable-next-line complexity
export const Topbar: FC<StackProps & { showKiroboLogo?: boolean }> = ({
  showKiroboLogo,
  ...props
}) => {
  const t = useTranslations();

  const network = useNetwork();
  const isUnsupportedNetwork = network?.chain?.unsupported;
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const { open: openDrawerMenu } = useDrawerMenuActions(drawerMenuId);

  const handleOpenDrawerMenu = useCallback(() => {
    openDrawerMenu({});
  }, [openDrawerMenu]);

  const { isServiceReady } = useSession();
  const { address } = useAccount();
  const user = useUser();
  const { connectHandler } = useConnectHandler();
  // const { data: networkData } = useSDKNetwork();
  const pathname = usePathname();
  const isTradingPath = pathname.includes('/trading');
  const isYieldPath = pathname.includes('/yields');
  const isPremiumPath = pathname.includes('/premium');
  const isMarketplaceHome =
    pathname.split('/').length === 2 &&
    (pathname.endsWith('/marketplace') || pathname.endsWith('/utils'));
  const isServicePage =
    isTradingPath || isYieldPath || isPremiumPath || isMarketplaceHome;
  const isMarketplaceApp = pathname.includes('/app/marketplace/');
  const isTradingApp = pathname.includes('/app/trading/');
  const isYieldApp = pathname.includes('/app/yields/');
  const isPremiumApp = pathname.includes('/app/premium/');
  const isAppPage =
    isMarketplaceApp || isTradingApp || isYieldApp || isPremiumApp;
  const isPublisherPath = pathname.includes('/publisher');
  const isAssetsPath =
    pathname.includes('/assets/tokens') || pathname.includes('/assets/nfts');
  const isAssetsHistory = pathname.includes('/assets/history');
  const isAppsHistory = pathname.includes('/apps/history');
  const isAppsPath =
    pathname.includes('/apps/active') || pathname.includes('/apps/expired');
  const isFlowsHistory = pathname.includes('/flows/history');
  const isFlowsPath =
    pathname.includes('/flows/active') || pathname.includes('/flows/expired');
  const isAlertsPath =
    pathname.includes('/flows/alerts') || pathname.includes('/apps/alerts');
  const pathArray = pathname.split('/');
  // const isNetworkSupported = networkData.raw.chainId > 0;
  const { isSigningIn } = user;

  // const { open: openAirdrop } = useAirdropModalActions(airdropId);

  const pickTitle = useCallback(() => {
    if (isServicePage || isAppPage) {
      return '';
    }
    if (isPublisherPath) {
      return t('publish-intents-to-the-marketplace');
    }
    if (isAssetsPath) {
      return replaceToIntents(pathArray[2]);
    }
    if (isFlowsPath || isAppsPath) {
      return replaceToIntents(`${pathArray[2]} ${pathArray[1]}`);
    }
    if (isAssetsHistory || isFlowsHistory || isAppsHistory || isAlertsPath) {
      return replaceToIntents(`${pathArray[1]} ${pathArray[2]}`);
    }
    return replaceToIntents(pathArray[1]);
  }, [
    isServicePage,
    isAppPage,
    isPublisherPath,
    isAssetsPath,
    isFlowsPath,
    isAppsPath,
    isAssetsHistory,
    isFlowsHistory,
    isAppsHistory,
    isAlertsPath,
    pathArray,
    t,
  ]);

  const title = pickTitle();

  return (
    <HStack
      {...props}
      pb="6"
      pt={isMobile ? '0' : '6'}
      px={isMobile ? '0' : '32px'}
    >
      {isMobile && <KiroboLogo narrowMode />}
      {!isMobile && isAppPage && (
        <HStack>
          <KiroboLogo />
        </HStack>
      )}
      {!isMobile && (
        <Heading size="md" textTransform="uppercase">
          {title}
        </Heading>
      )}
      <Spacer />
      {isServiceReady && (
        <>
          {!isMobile && <NetworkSwitcher />}
          {!isUnsupportedNetwork && (
            <ButtonGroup
              variant={user.isSignedIn ? 'ghost' : 'solid'}
              isAttached
            >
              <Button
                isLoading={isSigningIn}
                onClick={connectHandler}
                colorScheme={!user.isSignedIn ? 'blue' : undefined}
                leftIcon={
                  <Box pos="relative">
                    {user.isSignedIn && (
                      <Icon
                        pos="absolute"
                        left="50%"
                        top="50%"
                        transform="translate(-50%, -50%)"
                        as={UserOctagon}
                        variant="Bold"
                        color="green.500"
                        boxSize={5}
                      />
                    )}
                    <Icon
                      as={Wallet}
                      color={
                        // eslint-disable-next-line no-nested-ternary
                        user.isSignedIn ? 'gray.800' : ''
                      }
                      boxSize={user.isSignedIn ? 7 : 6}
                    />
                  </Box>
                }
              >
                {user.isSignedIn
                  ? shortenAddress(address)
                  : t('connect-wallet')}
              </Button>
            </ButtonGroup>
          )}
        </>
      )}
      {!isMobile && <Socials />}
      {!isMobile && !isColorModeOff && <ThemeToggle />}
      {isMobile && (
        <IconButton
          aria-label="menu"
          onClick={handleOpenDrawerMenu}
          icon={<HambergerMenu />}
        />
      )}
    </HStack>
  );
};

export default Topbar;
