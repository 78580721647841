/* eslint-disable complexity */

'use client';

/* eslint-disable sonarjs/cognitive-complexity */

import {
  Box,
  CloseButton,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Icon,
  Link,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Activity, Element3, Shop, Star1, WalletMoney } from 'iconsax-react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { useDeviceSelectors } from 'react-device-detect';

import { FlowPowerPreview } from '~/lib/components/flow/Power/FlowPowerPreview';

import { GuardWithMessage } from '~/lib/components/general/GuardWithMessage';
import {
  isBuilderDisabled,
  isMarketplaceOff,
  isPremiumOff,
} from '~/lib/helpers/flags';
import {
  tempIndicatorDefiAppsId,
  useDrawerStateMenu,
  useTempIndicatorDefiAppsModalActions,
} from '~/lib/hooks/useModals/useComplexModals';
import { BuilderSidebar } from './BuilderSidebar';
import { KiroboLogo } from './KiroboLogo';
import { PublisherSidebar } from './PublisherSidebar';

import { BigIntOr0, useActiveAppsList, useFlowPower } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import { BusinessInquiryButton } from './BusinessInquiryButton';
import { IntentRequestButton } from './IntentRequestButton';
import { SidebarLink } from './SidebarItem';

export const NOT_BASED = 'not_based';

export type BasedType =
  | 'orders'
  | 'apy'
  | 'indicator'
  | 'premium'
  | typeof NOT_BASED;

const setActiveColor = (based: BasedType) => {
  switch (based) {
    case NOT_BASED:
    case 'orders':
      return 'blue.400';
    case 'apy':
      return 'pink.400';
    case 'indicator':
      return 'purple.400';
    case 'premium':
      return '#ff5500';
    default:
      return 'gray.400';
  }
};

interface IMenuContent {
  isNarrowOption?: boolean;
}

const MenuContent: FC<IMenuContent> = ({ isNarrowOption }) => {
  const pathname = usePathname();
  const hide =
    Boolean(isNarrowOption) &&
    pathname.match(/(?:app\/)?marketplace\/(\w+)/) !== null;
  const [narrowMode, setNarrowMode] = useState(hide);

  const navigation = useRouter();

  const searchParams = useSearchParams();
  const basedParams = searchParams.get('based') || NOT_BASED;
  const activeColor = setActiveColor(basedParams as BasedType);

  useEffectOnce(() => {
    if (basedParams === 'apy' || basedParams === 'indicator') {
      navigation.replace(pathname);
    }
  });

  const relevantPath = pathname?.split('/')[1];

  const isNotBased = basedParams === NOT_BASED;
  const isActiveOrders =
    (basedParams === 'orders' || isNotBased) && relevantPath === 'trading';
  const isActiveIndicator =
    basedParams === 'indicator' && relevantPath === 'trading';

  const { open: openIndicatorIndicatorDefiApps } =
    useTempIndicatorDefiAppsModalActions(tempIndicatorDefiAppsId);

  const handleOpenTempIndicator = useCallback(() => {
    openIndicatorIndicatorDefiApps({});
  }, [openIndicatorIndicatorDefiApps]);

  useEffect(() => {
    setNarrowMode(hide);
  }, [hide]);

  const t = useTranslations();

  const {
    raw: {
      missing: { native: powerMissingWei },
    },
  } = useFlowPower();
  const activeApps = useActiveAppsList();

  const hasActiveApps = activeApps.list && Boolean(activeApps.list.length);

  const showNeededTokenAlert = BigIntOr0(powerMissingWei) > BigIntOr0(0);
  const isMissingPower = showNeededTokenAlert && hasActiveApps;

  return (
    <Stack
      onMouseEnter={hide ? () => setNarrowMode(false) : undefined}
      onMouseLeave={hide ? () => setNarrowMode(true) : undefined}
      transition="all 0.4s ease-out"
      alignItems="center"
      gap="6"
      width={narrowMode ? '77px' : '290px'}
      py="6"
      px={narrowMode ? '0' : '6'}
      bg="white"
      _dark={{
        bg: 'gray.800',
      }}
      h="full"
    >
      <Box alignSelf="flex-start" mx="8px">
        <KiroboLogo narrowMode={narrowMode} />
      </Box>
      <GuardWithMessage
        unsupportedNetworkJsx={
          <FlowPowerPreview
            narrowMode={narrowMode}
            label={t('unsupported-network')}
            isDisabled
          />
        }
        systemLoadingJsx={
          <FlowPowerPreview
            narrowMode={narrowMode}
            label={t('system-is-loading')}
            isDisabled
          />
        }
        serviceStateMessageJsx={
          <FlowPowerPreview
            narrowMode={narrowMode}
            label={t('service-is-offline')}
            isDisabled
          />
        }
        connectWalletMessageJsx={
          <FlowPowerPreview
            narrowMode={narrowMode}
            label={t('please-connect-wallet')}
            isDisabled
          />
        }
        signMessageJsx={
          <FlowPowerPreview
            narrowMode={narrowMode}
            label={t('please-login')}
            isDisabled
          />
        }
        signingMessageJsx={
          <FlowPowerPreview
            narrowMode={narrowMode}
            label={t('logging-in')}
            isDisabled
          />
        }
        notWhitelistedJsx={
          <FlowPowerPreview
            narrowMode={narrowMode}
            label={t('please-join-whitelist')}
            isDisabled
          />
        }
        missingVaultJsx={
          <FlowPowerPreview
            narrowMode={narrowMode}
            label={t('please-create-a-runner')}
            isDisabled
          />
        }
        notOwnerJsx={
          <FlowPowerPreview
            narrowMode={narrowMode}
            label={t('please-switch-main-wallet')}
            isDisabled
          />
        }
      >
        <FlowPowerPreview
          narrowMode={narrowMode}
          label={t('add-or-remove-gas')}
        />
      </GuardWithMessage>
      <Stack
        alignItems="center"
        overflowY="auto"
        overflowX="hidden"
        m="0"
        w="full"
        spacing={1}
        gap="4"
      >
        <Stack mt="0">
          {!isMarketplaceOff && (
            <SidebarLink
              narrowMode={narrowMode}
              href="/trading/top_pairs?based=orders"
              isActive={isActiveOrders}
              activeColor={activeColor}
              icon={Activity}
            >
              <Text as="span">{t('trading')}</Text>
            </SidebarLink>
          )}
          {!isPremiumOff && !isMarketplaceOff && (
            <SidebarLink
              narrowMode={narrowMode}
              href="/premium/buy_signals"
              isActive={relevantPath === 'premium'}
              activeColor={setActiveColor('premium')}
              icon={Activity}
            >
              <HStack gap="18px">
                <Text>{t('trading')}</Text>
                <HStack spacing="px">
                  <Icon
                    as={Star1}
                    variant="Bold"
                    boxSize="12px"
                    color="#ff5500"
                  />
                  <Text
                    bgGradient="linear(to-r, #ff5500, #90cc02)"
                    bgClip="text"
                    fontWeight="700"
                    textTransform="uppercase"
                    fontSize="12px"
                    mt="3px"
                  >
                    {t('premium')}
                  </Text>
                </HStack>
              </HStack>
            </SidebarLink>
          )}
          {!isMarketplaceOff && (
            <SidebarLink
              narrowMode={narrowMode}
              href="/yields/top_pairs"
              isActive={relevantPath === 'yields'}
              activeColor={setActiveColor('apy')}
              icon={Activity}
            >
              <HStack gap="18px">
                <Text>{t('trading')}</Text>
                {/* <Text color={isActiveApy ? activeColor : 'unset'}> */}
                <Text
                  color="pink.400"
                  fontWeight="700"
                  textTransform="uppercase"
                  fontSize="12px"
                  mt="3px"
                >
                  {t('yield-based')}
                </Text>
              </HStack>
            </SidebarLink>
          )}
          {!isMarketplaceOff && (
            <SidebarLink
              narrowMode={narrowMode}
              href="/trading/top_pairs?based=indicator"
              isActive={isActiveIndicator}
              activeColor={activeColor}
              icon={Activity}
              onClick={handleOpenTempIndicator}
            >
              <HStack gap="18px">
                <Text>{t('trading')}</Text>
                {/* <Text color={isActiveIndicator ? activeColor : 'unset'}> */}
                <Text
                  color="purple.400"
                  fontWeight="700"
                  textTransform="uppercase"
                  fontSize="12px"
                  mt="3px"
                >
                  {t('indicator-based')}
                </Text>
              </HStack>
            </SidebarLink>
          )}
          {!isMarketplaceOff && (
            <SidebarLink
              narrowMode={narrowMode}
              // href="/marketplace"
              // isActive={relevantPath === 'marketplace'}
              href="/utils"
              isActive={relevantPath === 'utils'}
              activeColor={activeColor}
              icon={Shop}
            >
              <Text as="span">{t('utility-apps')}</Text>
            </SidebarLink>
          )}
          {!isMarketplaceOff && (
            <SidebarLink
              narrowMode={narrowMode}
              href="/apps/active"
              isActive={relevantPath === 'apps'}
              activeColor={activeColor}
              icon={Element3}
              isMissingPowerSign={isMissingPower}
            >
              <Text as="span">{t('my-intents')}</Text>
            </SidebarLink>
          )}
          <SidebarLink
            narrowMode={narrowMode}
            href="/assets/tokens"
            isActive={relevantPath === 'assets'}
            activeColor={activeColor}
            icon={WalletMoney}
          >
            <Text as="span">{t('my-assets')}</Text>
          </SidebarLink>
          {/* {!isDappConnectOff && (
            <SidebarLink
              narrowMode={narrowMode}
              href="/dapp-connect"
              icon={Electricity}
            >
              <HStack>
                <Text>{t('dapp-connect')}</Text> <ConnectedDappIcons />
              </HStack>
            </SidebarLink>
          )} */}
        </Stack>
        <IntentRequestButton narrowMode={narrowMode} />
        {!isBuilderDisabled && (
          <BuilderSidebar
            narrowMode={narrowMode}
            relevantPath={relevantPath}
            activeColor={activeColor}
          />
        )}
        {!isBuilderDisabled && (
          <PublisherSidebar
            narrowMode={narrowMode}
            relevantPath={relevantPath}
            activeColor={activeColor}
          />
        )}
        <BusinessInquiryButton narrowMode={narrowMode} />
      </Stack>
      <Spacer />
      {/* <NewsletterPreview narrowMode={narrowMode} /> */}
      {/* {!isBuilderDisabled && <AIPreview narrowMode={narrowMode} />} */}
      {/* {!isMarketplaceOff && <BecomeABuilderButton narrowMode={narrowMode} />} */}
      {!isMarketplaceOff && (
        <Link
          whiteSpace="nowrap"
          color="blue.500"
          href="https://builder.intentable.io/"
          isExternal
        >
          {narrowMode ? 'Builder' : 'Builder App'}
        </Link>
      )}
      <Link
        whiteSpace="nowrap"
        color="blue.500"
        href="https://www.intentable.io/"
        isExternal
      >
        {narrowMode ? t('website') : t('intentable-website')}
      </Link>
    </Stack>
  );
};

const DrawerMenu: FC = () => {
  const { isOpen, close } = useDrawerStateMenu();

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  return (
    <Drawer isOpen={isOpen} placement="left" size="lg" onClose={handleClose}>
      <DrawerOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <DrawerContent boxShadow="none" bg="transparent">
        <HStack onClick={handleClose} spacing="0" h="full">
          <MenuContent />
          <Divider orientation="vertical" />
          <HStack
            alignItems="flex-start"
            spacing="0"
            p="32px"
            h="full"
            w="full"
          >
            <CloseButton />
          </HStack>
        </HStack>
      </DrawerContent>
    </Drawer>
  );
};

export const Sidebar: FC = () => {
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);

  if (isMobile) return <DrawerMenu />;

  return <MenuContent isNarrowOption={false} />;
};
